import * as React from "react";
import { Analytics } from "../Analytics";
interface IProps {
  part: any;
  baseImageUrl: any;
  isOffline: boolean;
}
function onClickEventGA(e: any) {
  let analytics = new Analytics();
  analytics.RecordEvent("rts-other-links", e.target.href);
}
function getChildrens(children: any) {
  let content: JSX.Element[] = [];
  for (let r of children) {
    content.push(
      <li key={r.Link}>
        <a onClick={onClickEventGA} href={r.Link} target="_blank">
          {r.DocumentName}
        </a>
      </li>
    );
  }
  return content;
}
function createResources(res: any) {
  let content: JSX.Element[] = [];
  // Outer loop to create parent
  for (let r of res) {
    let children: JSX.Element[] = [];
    // Inner loop to create children
    const rt = r.ResourceType;
    const rtlist = r.ResourceList;

    children.push(
      <ul key={rt} className="jumplist link">
        {getChildrens(rtlist)}
      </ul>
    );

    // Create the parent and add the children
    content.push(
      <div key={rt} className="col-sm">
        <h2>{rt}</h2>
        {children}
      </div>
    );
  }
  return content;
}
const Slide3: React.StatelessComponent<IProps> = ({
  part,
  baseImageUrl,
  isOffline
}) => {
  if (isOffline) {
    return (
      <div className="row">
        <div className="col">
          <div className="no-connection">
            <div className="row">
              <div className="col-sm-3">
                <img src="/img/icon_info_lg.svg" className="" />
              </div>
              <div className="col-sm-9">
                <h1>No Connection</h1>
                <p>
                  There is no internet connection found. If you would like to
                  view this page please connect to WI-FI or turn on your
                  Cellular Data.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (part.Resources === undefined) {
    return <React.Fragment />;
  }
  if (part.Resources === null) {
    return <React.Fragment />;
  }
  if (part.Resources === "") {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <h1 className="d-block d-md-none">RESOURCES</h1>
      <div className="row">{createResources(part.Resources)}</div>
    </React.Fragment>
  );
};
export default Slide3;
