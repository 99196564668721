import * as React from "react";
import { Part } from "../partsdata";
import ResultItem from "./ResultItem";
import Success from "./Success";

interface Props {
  items: Part[];
  term: string;
  moreItems: boolean;
}

export const ResultsComponent: React.StatelessComponent<Props> = ({
  items,
  term,
  moreItems
}) => {
 // console.log(moreItems);
  return (
    <div className="container">
      <Success term={term} moreResults={moreItems} />
      <div className="row">
        <div className="col">
          <ul className="jumplist complex">
            {items.map((item, index) => (
              <ResultItem key={index} part={item} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ResultsComponent;
