import * as React from "react";
import * as ReactDOM from "react-dom";
import { Analytics } from "../Analytics";
interface IProps {
  acc: any[];
}
function onClickEventGA(e: any) {
  let analytics = new Analytics();
  analytics.RecordEvent("rts-other-links", e.target.href);
}
function getAccessory(acc: any[]) {
  let content: JSX.Element[] = [];
  for (let r of acc) {
    content.push(
      <li key={r.AccessoryId}>
        <a onClick={onClickEventGA} href={r.URL} target="_blank">
          {r.AccessoryPart}
        </a>{" "}
        {r.DisplayName}
      </li>
    );
  }
  return content;
}
const Accessories: React.StatelessComponent<IProps> = ({ acc }) => {
  if (acc === null || acc === undefined) {
    return <React.Fragment />;
  }

  if (acc.length > 0) {
    return (
      <React.Fragment>
        <h2>What You Need</h2>
        <ul>{getAccessory(acc)}</ul>
      </React.Fragment>
    );
  }

  return <React.Fragment />;
};

export default Accessories;
