import config from "src/config";

const url = config.apiurl;
// let data: PartsData;
export const loadParts = async () => {
  return new Promise(resolve => {
    fetch(url)
      .then(response => response.json())
      .then(resolve);
  });
};
