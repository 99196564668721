import * as React from "react";
import DetailsFooter from "../details/DetailsFooter";

interface Props {
  term: string;
}

export const TooMany: React.StatelessComponent<Props> = ({ term }) => {
  return (
    <article className="notification blue">
      <div className="notificationTitle">Too many matches</div>
      <div className="notificationBody">
        <p>We have found too many results to display for: {term} </p>
        <p>
          Confirm the part number and return to <a href="/">home </a>
          to search again.
        </p>
        <DetailsFooter />
      </div>
    </article>
  );
};

export default TooMany;
