import * as React from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { updateRedirectRequest } from "src/reducers";
import { connect } from "react-redux";
import { Analytics } from "../Analytics";

// from typings
export interface IProps extends React.Props<Header> {
  parts: any;
  match: any; // <= What could I use here instead of any?
  location: any;
  history: any;
  single: boolean;
  shouldRedirect: boolean;
  term: string;
  results: any;
  redirecturl: string;
  cookies: any;
  updateRedirect: (sr: boolean, ru: string) => void;
}

class Header extends React.Component<IProps, {}> {
  constructor(props: any) {
    super(props);
    // console.log(props);
    // This binding is necessary to make `this` work in the callback
    this.backTo = this.backTo.bind(this);
    this.homeOnClick = this.homeOnClick.bind(this);
    this.infoOnClick = this.infoOnClick.bind(this);

    const lastVisited =
      localStorage.getItem("PartsFinderState") === null
        ? "/"
        : localStorage.getItem("PartsFinderState");
    // console.log(lastVisited);
    if (
      document.location.pathname.endsWith("/share") &&
      document.location.pathname.toLocaleLowerCase().startsWith("/details/")
    ) {
      this.props.history.push(document.location.pathname.replace("/share", ""));
    } else if (
      lastVisited !== undefined &&
      lastVisited !== document.location.pathname
    ) {
      this.props.history.push(lastVisited);
    }
  }
  homeOnClick(e: any) {
    e.preventDefault();
    this.props.updateRedirect(true, "/");
  }
  infoOnClick(e: any) {
    e.preventDefault();
    this.props.updateRedirect(true, "/info");
  }
  backTo(e: any) {
    e.preventDefault();
    // console.log(this.props.history);
    if (this.props.location.pathname.indexOf("/quicktips") === 0) {
      this.props.updateRedirect(true, "/");
    } else if (this.props.location.pathname.indexOf("/results/") === 0) {
      this.props.updateRedirect(true, "/");
    } else if (this.props.results.length === 1) {
      this.props.updateRedirect(true, "/");
    } else if (this.props.results.length > 1) {
      this.props.updateRedirect(true, "/results/" + this.props.term);
    } else {
      this.props.updateRedirect(true, "/");
    }

    // this.props.history.goBack();
    // console.log(this.props);
  }
  render() {
    if (this.props.shouldRedirect) {
      return <Redirect to={this.props.redirecturl} />;
    }
    let homeImg = (
      <a href="/" onClick={this.homeOnClick} className="header-link home">
        <img src="/img/icon_home.svg" className="icon" />
      </a>
    );
    let backImg = (
      <a href="/" onClick={this.backTo} className="header-link back">
        <img src="/img/icon_chevron_left.svg" className="icon" />
      </a>
    );

    if (this.props.location.pathname === "/") {
      backImg = <React.Fragment />;
      homeImg = (
        <a href="/info" onClick={this.infoOnClick} className="header-link home">
          <img src="/img/icon_info.svg" className="icon" />
        </a>
      );
    }

    return (
      <header>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="header">
                <div className="header-row">
                  <div className="header-cell icon-spot">{backImg}</div>

                  <div className="header-cell">
                    <img src="/img/honeywell_logo.svg" className="logo" />
                  </div>
                  <div className="header-cell icon-spot">{homeImg}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
const mapStateToProps = (state: any, customProps: any) => {
  return {
    xrefs: state.xrefs,
    fetching: state.fetching,
    fetched: state.fetched,
    filters: state.filters,
    resx: state.resx,
    parts: state.parts,
    baseurl: state.baseurl,
    error: state.error,
    term: state.term,
    redirect: state.redirect,
    results: state.results,
    moreresults: state.moreresults,
    selectedcx: state.selectedcx,
    selectedcu: state.selectedcu,
    selectedcb: state.selectedcb,
    shouldRedirect: state.shouldRedirect,
    redirecturl: state.redirecturl,
    cookies: customProps.cookies
  };
};
// export default withRouter(Header);
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateRedirect: (sr: boolean, ru: string) =>
      dispatch(updateRedirectRequest(sr, ru))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header));
