import * as React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updateRedirectRequest } from 'src/reducers';
import UpdateAvailable from './common/UpdateAvailable'; 
import { Analytics } from './Analytics';
interface IProps {
    version: string;
    lastupdated:string; 
    cacheupdated:boolean;
    updateRedirect: (sr: boolean, ru: string) => void; 
    cookies: any;
}


class Info extends React.Component<IProps, {}> {
    constructor(props: any) {
        super(props);      
        let analytics = new Analytics();
        analytics.RecordEvent("rts-page-name",document.location.pathname);
    }
    componentWillMount(){
      //  console.log("info will mount");
    }
    componentDidMount(){
        this.props.updateRedirect(false, "/");
    //    console.log('info did mount');
        // this.props.cookies.set("PartsFinderState", document.location.pathname);
        localStorage.setItem("PartsFinderState", document.location.pathname);
    }
    infoOnResideoClick(e:any){
        let analytics = new Analytics();
        analytics.RecordEvent("rts-other-links", e.target.href);
    }
        render() {
         //   console.log("info render");
        return(
<div className="container">
                <UpdateAvailable show={this.props.cacheupdated} />
    <div className="row">
        <div className="col">
            <div className="info">
                <h1>About Parts Finder</h1>
                <div className="version">Software Version {this.props.version} <br /> <span>Database last updated: <br /> {this.props.lastupdated}</span></div>

                
                <h2>Contact Customer Care</h2>
                <p><a href="tel:18004681502">+1 (800) 468-1502</a></p>
                <p><a target="_blank" rel="noopener noreferrer" href="mailto:ecccustomercare@resideo.com">ecccustomercare@resideo.com</a></p>
                <p><a href="https://customer.resideo.com" onClick={this.infoOnResideoClick} target="_blank">https://customer.resideo.com</a></p>
                <div className="divide">{" "}</div>
                            <p><a href="https://www.resideo.com/eula/english-us" onClick={this.infoOnResideoClick}  target="_blank">EULA</a></p>
                            <p><a href="https://www.resideo.com/privacy" onClick={this.infoOnResideoClick} target="_blank">Privacy Policy</a></p>
                            <p><a href="https://www.resideo.com/terms" onClick={this.infoOnResideoClick} target="_blank">Terms &amp; Conditions</a></p>

                <div className="copyright">
                    &copy;2019 Resideo Technologies, Inc. The Honeywell Home trademark is used under the license from Honeywell International, Inc.
                </div>
            </div>
        </div>
    </div>
</div>
 );
  
}
}
const mapStateToProps = (state: any, customProps: any) => {
  return {
    redirecturl: state.redirecturl,
    version: state.version,
    lastupdated: state.lastupdated,
    cacheupdated: state.cacheupdated,
    cookies: customProps.cookies
  };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        updateRedirect: (sr: boolean, ru: string) =>
            dispatch(updateRedirectRequest(sr, ru))
    };
};
export default connect(
     mapStateToProps,
    mapDispatchToProps
)(withRouter(Info));
