import * as React from "react";
interface IProps {}
export const DetailsFooter: React.StatelessComponent<IProps> = () => {
  return (
    <React.Fragment>
      <div className="notificationTitle">Need Help?</div>
      <div className="notificationBody">
        <ul className="list-unstyled list-contact">
          <li>
            <img src="/img/icon_envelope.svg" className="contact-icon" />
            <a target="_blank" rel="noopener noreferrer" href="mailto:ecccustomercare@resideo.com">ecccustomercare@resideo.com</a>
          </li>
          <li>
            <img src="/img/icon_phone.svg" className="contact-icon" />
            <a href="tel:18004681502">+1 (800) 468-1502</a>
          </li>
        </ul>
      </div>{" "}
    </React.Fragment>
  );
};

export default DetailsFooter;
