class Analytics {
  static initialize() {
    // ReactGA.initialize(config.analyticsId);
  }

  PageView(link: string) {
    // console.log(link);
    // ReactGA.pageview(link);
    let dl: any = window["dataLayer"] as any;
    // dl.push({ event: "page-name", name: link });
    dl.push({ event: "Pageview", url: link });
  }

  RecordEvent(eventname: string, act: string) {
    // console.log(cat, act);
    // ReactGA.event({
    //   category: cat,
    //   action: act
    // });

    let dl: any = window["dataLayer"] as any;
    dl.push({ event: eventname, name: act });
  }
}
Analytics.initialize();
export { Analytics };
