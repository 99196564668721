import * as React from "react";
import { Part } from "../partsdata";
import { connect } from "react-redux";

import { redirectDetailsRequest } from "src/reducers";
import { Redirect } from "react-router";
interface IProps extends React.Props<ResultItem> {
  part: any;

  redirectDetails: (
    cu: string,
    cx: string,
    cb: string,
    sr: boolean,
    ru: string
  ) => void;
}
class ResultItem extends React.Component<IProps, {}> {
  constructor(props: any) {
    super(props);
    this.selectDetails = this.selectDetails.bind(this);
  }

  selectDetails(e: any) {
    e.preventDefault();
    this.props.redirectDetails(
      this.props.part.cu,
      this.props.part.cx,
      this.props.part.cb,
      true,
      "/details/" + encodeURIComponent(this.props.part.cu)
    );
  }

  render() {
    return (
      <li>
        <a href="" onClick={this.selectDetails}>
          <div className="jumpObject">
            <div className="jumpTitle">{this.props.part.cu} </div>
            <div className="jumpBody">{this.props.part.cat}</div>
          </div>
        </a>
      </li>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    xrefs: state.xrefs,
    fetching: state.fetching,
    fetched: state.fetched,
    filters: state.filters,
    resx: state.resx,
    parts: state.parts,
    baseurl: state.baseurl,
    error: state.error,
    term: state.term,
    redirect: state.redirect,
    results: state.results,
    details: state.details,
    tabid: state.tabid,
    width: state.width,
    single: state.single,
    selectedcx: state.selectedcx,
    selectedcu: state.selectedcu
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    redirectDetails: (
      cu: string,
      cx: string,
      cb: string,
      sr: boolean,
      ru: string
    ) => dispatch(redirectDetailsRequest(cu, cx, cb, sr, ru))
  };
};

export default connect(
  mapDispatchToProps,
  mapDispatchToProps
)(ResultItem);
