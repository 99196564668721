import * as React from "react";
interface Props {
  term: string;
}
const NoResults: React.StatelessComponent<Props> = ({ term }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <article className="notification orange">
            <div className="notificationTitle">No Matches Found</div>
            <div className="notificationBody">
              <p>No matches found for your search for {term}.</p>
              <p>
                Confirm the part number and return to
                <a href="/"> home</a> to search again.
              </p>
              <p>
                <strong>Need Help?</strong>
              </p>
              <ul className="list-unstyled list-contact">
                <li>
                  <img src="/img/icon_envelope.svg" className="contact-icon" />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="mailto:ecccustomercare@resideo.com"
                  >
                    ecccustomercare@resideo.com
                  </a>
                </li>
                <li>
                  <img src="/img/icon_phone.svg" className="contact-icon" />
                  <a href="tel:18004681502">+1 (800) 468-1502</a>
                </li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default NoResults;
