import "react-app-polyfill/ie11";
import "airbnb-js-shims";

import * as React from "react";
import { FETCH_COMPLETED } from "./reducers/actions";
//  import axios from "axios";
import * as ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
// import registerServiceWorker from "./registerServiceWorker";
// import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { dataReducer } from "./reducers/DataReducer";
import { loadParts } from "./reducers/DataService";
// console.log("test index");
// alert("index test");
loadParts().then(parts => {
  //  console.log('index');
  // console.log(parts);
  const store = createStore(dataReducer, applyMiddleware(thunk));
  store.dispatch((dispatch: any) => {
    dispatch({ type: FETCH_COMPLETED, payload: parts });
  });

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root") as HTMLElement
  );

  // serviceWorker.unregister();
});
