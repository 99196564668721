import * as React from "react";

interface Props {
  show: boolean;
}

export const UpdateAvailable: React.StatelessComponent<Props> = ({ show }) => {
  if (show === true) {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <article className="notification green">
              <div className="notificationTitle">Update Available!</div>
              <div className="notificationBody">
                Update available, close all instances of this app and reopen
                again
              </div>
            </article>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <React.Fragment />;
  }
};

export default UpdateAvailable;
