import * as React from "react";
interface Props {
  overview: string;
}
const ProductOverview: React.StatelessComponent<Props> = ({ overview }) => {
  if (overview === undefined || overview.length === 0) {
    return <React.Fragment />;
  } else {
    return (
      <React.Fragment>
        <h2>Product Overview</h2>
        <p>{overview}</p>
      </React.Fragment>
    );
  }
};

export default ProductOverview;
