import * as React from "react";
import TooMany from "./TooMany";

interface Props {
  term: string;
  moreResults: boolean;
}

export const Success: React.StatelessComponent<Props> = ({
  term,
  moreResults
}) => {
  if (moreResults) {
    return <TooMany term={term} />;
  } else {
    return (
      <article className="notification green">
        <div className="notificationTitle">Success!</div>
        <div className="notificationBody">
          We have found some Honeywell matches for: {term}
        </div>
      </article>
    );
  }
};

export default Success;
