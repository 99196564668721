import * as React from "react";
interface IProps {
  show: boolean;
}
export const MatchPart: React.StatelessComponent<IProps> = ({ show }) => {
  if (show) {
    return (
      <article className="notification green vanish">
        <div className="notificationTitle">Success!</div>
        <div className="notificationBody">We found a match.</div>
      </article>
    );
  } else {
    return <React.Fragment />;
  }
};
export default MatchPart;
