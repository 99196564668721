import * as React from "react";
interface IProps {
  term: string;
  brand: string;
}

const MatchFor: React.StatelessComponent<IProps> = ({ term, brand }) => {
  if (term === "") {
    return <React.Fragment />;
  }
  return (
    <p>
      Honeywell Home match for: {brand} {term}{" "}
    </p>
  );
};
export default MatchFor;
