import * as React from "react";
import { withRouter, Redirect } from "react-router";
import {
  updateDetailsRequest,
  selectedTabRequest,
  updateWidthRequest
} from "src/reducers";
import { connect } from "react-redux";
import MatchPart from "./details/MatchPart";
import MatchFor from "./details/MatchFor";
import Tabs from "./details/Tabs";
import UpdateAvailable from "./common/UpdateAvailable";
import { Analytics } from "./Analytics";

interface IProps extends React.Props<Details> {
  selectedcx: string;
  selectedcu: string;
  selectedcb: string;
  width: number;
  single: boolean;
  match: any;
  updateDetails: (id: string, xprod: string, cb: string) => void;
  details: any;
  timeout: number;
  cookies: any;
  cacheupdated: boolean;
  updateWidth: (width: number) => void;
  lastUrl: string;
}

class Details extends React.Component<IProps, {}> {
  constructor(props: any) {
    super(props);

    if (this.props.selectedcu === "") {
      this.props.updateDetails(
        decodeURIComponent(this.props.match.params.id),
        this.props.selectedcx,
        this.props.selectedcb
      );
    } else {
      this.props.updateDetails(
        this.props.selectedcu,
        this.props.selectedcx,
        this.props.selectedcb
      );

      this.trackShareThis = this.trackShareThis.bind(this);
    }
    let analytics = new Analytics();
    analytics.RecordEvent("rts-page-name", document.location.pathname);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions(e: any) {
    // console.log(e);
    e.preventDefault();
    const wid = $(window).width() as number;
    if (wid < 992) {
      $(".js_owl").owlCarousel({
        loop: false,
        items: 1,
        responsiveClass: true,
        margin: 30,
        autoHeight: false
      });
    } else {
      $(".js_owl").owlCarousel({
        loop: false,
        items: 1,
        responsiveClass: true,
        margin: 30,
        autoHeight: false
      });
    }
  }
  componentWillMount() {
    document.body.className = "";
    // window.addEventListener("resize", this.updateDimensions);
    this.props.updateWidth($(window).width() as number);
  }
  componentDidMount() {
    if (this.props.single) {
      $(".notification.vanish")
        .delay(this.props.timeout)
        .slideUp();
    }

    localStorage.setItem("PartsFinderState", document.location.pathname);
  }

  trackShareThis(e: any) {
    let analytics = new Analytics();
    analytics.RecordEvent("rts-share-this", this.props.details.cu);
  }
  render() {
    const show = this.props.single;
    if (this.props.details === undefined) {
      return <React.Fragment>loading...</React.Fragment>;
    }

    return (
      <div className="container">
        <UpdateAvailable show={this.props.cacheupdated} />
        <div className="row">
          <div className="col">
            <MatchPart show={show} />
            <MatchFor
              term={this.props.selectedcx}
              brand={this.props.selectedcb}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <h1>{this.props.details.cu}</h1>
            <h2>{this.props.details.cat} </h2>
          </div>
          <div className="col">
            <a
				target="_blank" 
				rel="noopener noreferrer"
              href={
                "mailto:?subject= Honeywell Home Product​​​​: " +
                this.props.details.cu +
                "&body=Hello,%0D%0A%0D%0AI wanted to share this product with you that I found on the Resideo Parts Finder site: " +
                this.props.details.cu +
                "%0D%0A" +
                encodeURI(document.location.href) +
                "/share" +
                "%0D%0A%0D%0AThank you."
              }
              className="btn btn-outline btn-share"
              onClick={this.trackShareThis}
            >
              Share this product
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <hr />
          </div>
        </div>
        <Tabs />
      </div>
    );
  }
}
const mapStateToProps = (state: any, customProps: any) => {
  return {
    xrefs: state.xrefs,
    fetching: state.fetching,
    fetched: state.fetched,
    filters: state.filters,
    resx: state.resx,
    parts: state.parts,
    baseurl: state.baseurl,
    error: state.error,
    term: state.term,
    redirect: state.redirect,
    results: state.results,
    details: state.details,
    tabid: state.tabid,
    width: state.width,
    single: state.single,
    selectedcx: state.selectedcx,
    selectedcu: state.selectedcu,
    selectedcb: state.selectedcb,
    timeout: state.successtimeout,
    cacheupdated: state.cacheupdated,
    cookies: customProps.cookies,
    lastUrl: state.lastUrl
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateDetails: (term: string, xprod: string, brand: string) =>
      dispatch(updateDetailsRequest(term, xprod, brand)),
    selectedTab: (tab: string) => dispatch(selectedTabRequest(tab)),
    updateWidth: (width: number) => dispatch(updateWidthRequest(width))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Details));
