import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from "./components/common/Header";
import Home from "./components/Home";
import Details from "./components/Details";
import Results from "./components/Results";
import { connect } from "react-redux";
import { updateCacheRequest } from "./reducers/actions";
import Info from "./components/Info";
import Quicktips from "./components/Quicktips";

import { Analytics } from "./components/Analytics";
import config from "./config";
interface IChildComponentProps extends React.Props<any> {
  updateCache: () => void;

  lastUrl: string;
}

interface PassedProps extends React.Props<any> {
  propToPass: any;
}

class App extends React.Component<IChildComponentProps, PassedProps> {
  constructor(props: any) {
    super(props);
    window.addEventListener("newPartsDataAvailable", () => {
      // console.log("update available constructor");
      // window.location.reload(true);
      // this.props.isSingle(false);
      this.props.updateCache();
    });
  }

  componentWillMount() {
    // console.log("app componentWillMount");
  }
  initializeReactGA() {
    // console.log("analytics initialized");
    let analytics = new Analytics();
    analytics.PageView("/home");
  }
  componentDidMount() {
    this.initializeReactGA();
  }
  shouldRedirect() {}
  render() {
    // console.log("app render");
    // alert("app");
    return (
      <Router>
        <React.Fragment>
          <Header />
          <Switch>
            <Route exact path="/" render={() => <Home />} />
            <Route path="/info" render={() => <Info />} />
            <Route path="/quicktips" render={() => <Quicktips />} />
            <Route path="/results/:term" render={() => <Results />} />
            <Route path="/details/:id" render={() => <Details />} />
          </Switch>
        </React.Fragment>
      </Router>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    parts: state.parts,
    cacheupdated: state.cacheupdated,
    xrefs: state.xrefs,
    lastUrl: state.lastUrl
  };
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    updateCache: () => dispatch(updateCacheRequest())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
