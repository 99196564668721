import {
  FETCH_START,
  FETCH_COMPLETED,
  FETCH_FAILURE,
  UPDATE_SEARCH_TERM,
  UPDATE_SELECT_TERM,
  UPDATE_DETAILS_ID,
  UPDATE_TAB_ID,
  UPDATE_WIDTH,
  UPDATE_IS_SINGLE,
  REDIRECT_TO,
  REDIRECT_TO_DETAILS,
  UPDATE_SINGLE_DETAILS_ID,
  UPDATE_SERVICEWORKER,
  UPDATE_CACHE,
  RESET_CACHE
} from "./actions";

import { any, string, func } from "prop-types";
import { PartsData } from "src/components/partsdata";
import config from "../config";

const initialState = {
  parts: any,
  resx: any,
  xrefs: any,
  filters: [],
  baseurl: string,
  fetched: false,
  fetching: false,
  error: false,
  term: string,
  redirect: false,
  results: [],
  details: any,
  tabid: "tab1",
  single: false,
  width: $(window).width(),
  version: "",
  lastupdated: "",
  selectedcx: "",
  selectedcu: "",
  selectedcb: "",
  successtimeout: config.successtimeout,
  shouldRedirect: false,
  isIos: false,
  redirecturl: "/",
  cacheupdated: false,
  onlyselected: false
};

export const dataReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case RESET_CACHE: {
      // console.log("resetcache");

      return {
        initialState
      };
    }
    case UPDATE_CACHE: {
      return { ...state, cacheupdated: true };
    }
    case UPDATE_SERVICEWORKER: {
      return { ...state };
    }
    case UPDATE_SINGLE_DETAILS_ID: {
      const res = getDetails(state.parts, action.payload.cu);
      return {
        ...state,
        details: res,
        tabid: "tab1",
        selectedcx: action.payload.cx,
        selectedcu: action.payload.cu,
        selectedcb: action.payload.cb,
        shouldRedirect: false,
        width: $(window).width() as number,
        single: action.payload.sing
      };
    }
    case REDIRECT_TO_DETAILS: {
      const res = getDetails(state.parts, action.payload.cu);

      return {
        ...state,
        tabid: "tab1",
        shouldRedirect: action.payload.sr,
        redirecturl: action.payload.ru,
        selectedcx: action.payload.cx,
        selectedcu: action.payload.cu,
        selectedcb: action.payload.cb,
        redirect: false,
        single: false
      };
    }
    case REDIRECT_TO: {
      let selcb = state.selectedcb;
      let selcx = state.selectedcx;
      let selcu = state.selectedcu;
      let res1 = state.result;

      if (action.payload.ru.indexOf("/results/") === 0) {
        selcb = "";
        selcx = "";
        selcu = "";
      } else if (action.payload.ru === "/") {
        selcb = "";
        selcx = "";
        selcu = "";
        res1 = [];
      }
      return {
        ...state,
        shouldRedirect: action.payload.sr,
        redirecturl: action.payload.ru,
        selectedcb: selcb,
        selectedcu: selcu,
        selectedcx: selcx,
        redirect: false,
        single: false,
        results: res1
      };
    }
    case UPDATE_IS_SINGLE: {
      //  console.log(action.payload);
      return { ...state, single: action.payload, shouldRedirect: false };
    }
    case UPDATE_WIDTH: {
      return { ...state, width: action.payload, shouldRedirect: false };
    }
    case UPDATE_TAB_ID: {
      return { ...state, tabid: action.payload, shouldRedirect: false };
    }
    case UPDATE_DETAILS_ID: {
      const res = getDetails(state.parts, action.payload.cu);

      return {
        ...state,
        details: res,
        selectedcx: action.payload.cx,
        selectedcu: action.payload.cu,
        selectedcb: action.payload.cb,
        shouldRedirect: false,
        width: $(window).width() as number
      };
    }
    case UPDATE_SELECT_TERM: {
      // var selonly: boolean =
      //   action.payload.selonly === undefined ? false : action.payload.selonly;
      // console.log(selonly);
      const res = getResults(
        state.xrefs,
        state.parts,
        action.payload.toLowerCase(),
        true
      );

      return {
        ...state,
        term: action.payload,
        results: res[1],
        moreresults: res[0],
        onlyselected: true,
        shouldRedirect: false,
        redirect: action.payload.length > 2
      };
    }
    case UPDATE_SEARCH_TERM: {
      // console.log("payload:" + action.term);

      const res = getResults(state.xrefs, state.parts, action.payload, false);

      return {
        ...state,
        term: action.payload,
        results: res[1],
        moreresults: res[0],
        onlyselected: false,
        shouldRedirect: false,
        redirect: action.payload.length > 2
      };
    }

    case FETCH_FAILURE: {
      return { ...state, fetching: false, error: true, shouldRedirect: false };
    }
    case FETCH_COMPLETED: {
      //  console.log("fetched");
      const pd: PartsData = action.payload as PartsData;
      const uniqueParams = pd.xrefs.map(item => item.cx).filter(onlyUnique);

      return {
        ...state,
        fetching: false,
        fetched: true,
        parts: pd.parts,
        xrefs: pd.xrefs,
        resx: pd.resx,
        filters: uniqueParams,
        baseurl: pd.baseurl,
        term: "",
        results: [],
        details: any,
        tabid: "tab1",
        width: $(window).width() as number,
        single: false,
        version: config.version,
        lastupdated: pd.version,
        onlyselected: false,
        moreresults: false,
        selectedcu: "",
        selectedcx: "",
        selectedcb: "",
        shouldRedirect: false,
        redirecturl: "/",
        successtimeout: config.successtimeout
      };
    }
    case FETCH_START: {
      return { ...state, fetching: true };
    }
  }

  return { state };
};

function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}

function getResults(xrefs: any, parts: any, term: string, only: boolean) {
  // console.log(only);
  let count = 0;
  let returndata: any[] = [];
  let items1: any[] = [];
  returndata[0] = false;
  returndata[1] = [];
  if (term.length > 2) {
    //  console.log(items1.count);
    const items2: any = xrefs.filter((item: any) =>
      (item.cx.toLowerCase() || item.cu.toLowerCase()).includes(
        term.toLowerCase()
      )
    );

    items2.forEach(element => {
      if (element.cx.toLowerCase() === term) {
        items1.push(element);
      }
    });
    items2.forEach(element => {
      if (element.cu.toLowerCase() === term) {
        items1.push(element);
      }
    });
    if (only === false) {
      items2.forEach(element => {
        if (
          element.cx.toLowerCase() !== term ||
          element.cu.toLowerCase() !== term
        ) {
          items1.push(element);
        }
      });
    }

    let uniqueItems: any[] = [];

    for (let i1 of items1) {
      if (uniqueItems.indexOf((i1 as any).cu) === -1) {
        uniqueItems.push((i1 as any).cu);
      }
      if (uniqueItems.length > 10) {
        break;
      }
    }

    count = uniqueItems.length;
    if (count === 1) {
      returndata[0] = false;
      var itemstemp: any[] = [];
      itemstemp.push(items1[0]);
      returndata[1] = itemstemp;
      return returndata;
    } else if (count > 10) {
      returndata[0] = true;

      let items4: any[] = [];
      for (let i1 of uniqueItems.slice(0, 10)) {
        //  console.log(i1);
        let t2 = items1.filter((item: any) => item.cu === i1);
        if (t2.length > 0) {
          let p = parts.filter((p1: any) => p1.cu === i1);
          //  console.log(p);
          p[0].cx = t2[0].cx;
          p[0].cb = t2[0].cb;
          items4.push(p[0]);
        }
      }
      returndata[1] = items4;
      return returndata;
    } else {
      returndata[0] = false;
      let items4: any[] = [];
      for (let i1 of uniqueItems) {
        let t2 = items1.filter((item: any) => item.cu === i1);
        if (t2.length > 0) {
          let p = parts.filter((p1: any) => p1.cu === i1);

          p[0].cx = t2[0].cx;
          p[0].cb = t2[0].cb;
          items4.push(p[0] as any);
        }
      }
      returndata[1] = items4;
      return returndata;
    }
  } else {
    returndata[1] = [];
    return returndata;
  }
}

function getDetails(parts: any, id: string) {
  const part = parts.filter((item: any) => item.cu === id);
  if (part.length === 1) {
    const result = parts.filter((p1: any) => p1.cu === id);
    return result[0];
  }
  return null;
}
