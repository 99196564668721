import * as React from "react";
import { withRouter } from "react-router";
import { updateRedirectRequest } from "src/reducers";
import { connect } from "react-redux";
import { Analytics } from "./Analytics";

interface IProps {
  updateRedirect: (sr: boolean, ru: string) => void;
  cookies: any;
  lastUrl: string;
}

class Quicktips extends React.Component<IProps, {}> {
  constructor(props: any) {
    super(props);
  }
  componentDidMount() {
    this.props.updateRedirect(false, "/quicktips");
    localStorage.setItem("PartsFinderState", document.location.pathname);
    let analytics = new Analytics();
    analytics.RecordEvent("rts-page-name", document.location.pathname);
  }

  onClickEventGA(e: any) {
    let analytics = new Analytics();
    analytics.RecordEvent("rts-other-links", e.target.href);
  }

  render() {
    return (
      <div className="help">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Quick tips</h1>
             
              <h2>To pin the Parts Finder to your home screen:</h2>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <h3>Apple Devices</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <div className="tipNumber">1</div>
              <p>
                Open your browser app and go to{" "}
                <a
                  onClick={this.onClickEventGA}
                  href="https://partsfinder.resideo.com"
                  target="_blank"
                >
                  partsfinder.resideo.com
                </a>
              </p>
            </div>
            <div className="col-md">
              <div className="tipNumber">2</div>
              <p>
                Select the <img src="/img/iosshare.svg" className="iosshare" />{" "}
                icon at the bottom of the browser, then scroll to the left until
                you see "Add to Home Screen"
              </p>
            </div>
            <div className="col-md">
              <div className="tipNumber">3</div>
              <p>Select Add in the top-right corner and you’re done!</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="status">
                An icon will appear on your home screen for quick one-tap visits
                to the Parts Finder service on your device.
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="pageDivide">&nbsp;</div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <h3>Android Devices</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <div className="tipNumber">1</div>
              <p>
                Open your browser app and go to{" "}
                <a href="https://partsfinder.resideo.com" target="_blank">
                  partsfinder.resideo.com
                </a>
              </p>
            </div>
            <div className="col-md">
              <div className="tipNumber">2</div>
              <p>
                Select the menu button and tap "Add to Home Screen"; you’ll have
                the option to enter a shortcut name
              </p>
            </div>
            <div className="col-md">
              <div className="tipNumber">3</div>
              <p>Select Add and you’re done! </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="status">
                An icon will appear on your home screen for quick one-tap visits
                to the Parts Finder service on your device.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any, customProps: any) => {
  return {
    redirecturl: state.redirecturl,
    version: state.version,
    lastupdated: state.lastupdated,
    cookies: customProps.cookies,
    lastUrl: state.lastUrl
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateRedirect: (sr: boolean, ru: string) =>
      dispatch(updateRedirectRequest(sr, ru))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Quicktips));
