import * as React from "react";
import {
  updateSearchTermRequest,
  updateDetailsRequest,
  isSingleRequest,
  updateSinglePartDetailsRequest,
  updateSelectRequest
} from "../reducers/actions";
import { withRouter, Redirect } from "react-router-dom";
import { Props } from "./common/CommonInterfaces";
import { connect } from "react-redux";
import NoResults from "./common/NoResults";
import ResultsComponent from "./common/ResultsComponent";
import { Analytics } from "./Analytics";

interface IProps extends React.Props<Results> {
  updateSelectTerm: (term: string) => void;
  updateSearchTerm: (term: string) => void;
  match: any;
  parts: any;
  results: any[];
  updateDetails: (id: string, xprod: string, cb: string, sing: boolean) => void;
  isSingle: (single: boolean) => void;
  moreresults: boolean;
  selectedcx: string;
  selectedcu: string;
  selectedcb: string;
  term: string;
  cookies: any;
  lastUrl: string;
  onlyselected: boolean;
}
class Results extends React.Component<IProps, {}> {
  constructor(props: any) {
    super(props);
    // console.log(this.props);
    if (this.props.onlyselected) {
      this.props.updateSelectTerm(this.props.match.params.term);
    } else if (
      this.props.results === undefined ||
      this.props.results.length === 0
    ) {
      this.props.updateSearchTerm(this.props.match.params.term);
    } else {
      this.props.updateSearchTerm(this.props.match.params.term);
    }

    let analytics = new Analytics();
    analytics.RecordEvent("rts-page-name", document.location.pathname);
  }

  componentWillMount() {
    document.body.className = "";
    if (this.props.parts === undefined) {
      const url = "/data.json";
      fetch(url)
        .then(response => response.json())
        .then(data => {
          // this.setState({ rtsdata: data });
        });
    }

    if (this.props.results !== undefined && this.props.results.length === 1) {
      const res1 = this.props.results[0];
      this.props.updateDetails(
        decodeURIComponent(res1.cu),
        res1.cx,
        res1.cb,
        true
      );
    } else if (
      this.props.results !== undefined &&
      this.props.results.length > 0
    ) {
      this.props.isSingle(false);
    }
  }
  componentDidMount() {
    // this.props.cookies.set("PartsFinderState", document.location.pathname);
    localStorage.setItem("PartsFinderState", document.location.pathname);
  }
  componentDidUpdate() {
    if (this.props.results === undefined || this.props.results.length === 0) {
      let analytics = new Analytics();
      analytics.RecordEvent("rts-no-results", this.props.term);
    }
  }
  render() {
    if (this.props.results === undefined || this.props.results.length === 0) {
      return <NoResults term={this.props.term} />;
    } else if (this.props.results.length === 1) {
      const result: any = this.props.results;
      if (result[0].cu === undefined || result[0].cu.length === 0) {
        return <NoResults term={this.props.term} />;
      }
      let analytics = new Analytics();
      analytics.RecordEvent("oneresult", result[0].cu);
      return <Redirect to={"/details/" + encodeURIComponent(result[0].cu)} />;
    } else if (
      this.props.selectedcx.length > 0 &&
      this.props.selectedcu.length > 0
    ) {
      let analytics = new Analytics();
      analytics.RecordEvent("oneresult", this.props.selectedcu);
      return (
        <Redirect
          to={"/details/" + encodeURIComponent(this.props.selectedcu)}
        />
      );
    } else {
      let analytics = new Analytics();
      analytics.RecordEvent("results", this.props.term);
      return (
        <ResultsComponent
          items={this.props.results}
          term={this.props.term}
          moreItems={this.props.moreresults}
        />
      );
    }
  }
}
const mapStateToProps = (state: any, customProps: any) => {
  return {
    term: state.term,
    selectedcx: state.selectedcx,
    selectedcu: state.selectedcu,
    selectedcb: state.selectedcb,
    moreresults: state.moreresults,
    results: state.results,
    parts: state.parts,
    error: state.error,
    redirect: state.redirect,
    cookies: customProps.cookies,
    onlyselected: state.onlyselected,
    lastUrl: state.lastUrl
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSelectTerm: (term: string) => dispatch(updateSelectRequest(term)),
    updateSearchTerm: (term: string) => dispatch(updateSearchTermRequest(term)),
    updateDetails: (term: string, xprod: string, cb: string, sing: boolean) =>
      dispatch(updateSinglePartDetailsRequest(term, xprod, cb, sing)),
    isSingle: (single: boolean) => dispatch(isSingleRequest(single))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Results));
