import * as React from "react";
import {
  UPDATE_SEARCH_TERM,
  updateSearchTermRequest,
  updateRedirectRequest,
  resetCacheRequest,
  updateSelectRequest
} from "../reducers/actions";
import { withRouter } from "react-router";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import UpdateAvailable from "./common/UpdateAvailable";

import { Analytics } from "./Analytics";

interface IProps extends React.Props<Home> {
  parts: any;
  resx: any;
  xrefs: any;
  baseurl: string;
  fetched: false;
  fetching: false;
  error: false;
  filters: any[];
  term: string;
  redirect: false;
  cacheupdated: false;
  lastUrl: string;
  updateSearchTerm: (term: string) => void;
  updateSelectTerm: (term: string) => void;
  resetCache: () => void;
  updateRedirect: (sr: boolean, ru: string) => void;
  cookies: any;
}

class Home extends React.Component<IProps, {}, {}> {
  private searchref: any;

  constructor(props: any) {
    super(props);
    // console.log("home component");
    this.props.updateSearchTerm("");

    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.submit = this.submit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.quickTipsOnClick = this.quickTipsOnClick.bind(this);
    this.searchref = React.createRef();
    // console.log(this.props);
    this.resetCacheData = this.resetCacheData.bind(this);
    // setting a cookie
    let analytics = new Analytics();
    analytics.RecordEvent("rts-page-name", "/home");
  }
  componentWillMount() {
    document.body.className = "home";
    // console.log(this.props);
  }
  componentDidMount() {
    // console.log(this.props.lastUrl);
    // this.props.cookies.set("PartsFinderState", document.location.pathname);
    localStorage.setItem("PartsFinderState", document.location.pathname);
  }
  submit(e: React.FormEvent) {
    e.preventDefault();
    if (this.searchref.current.getInstance().state.text.trim().length > 2) {
      let analytics = new Analytics();
      analytics.RecordEvent(
        "rts-search-click",
        this.searchref.current.getInstance().state.text
      );
      this.props.updateSearchTerm(
        this.searchref.current.getInstance().state.text
      );
    }

    return null;
  }

  onChange(e: any) {
    // console.log(e);
    this.props.updateSelectTerm(e[0]);
    if (e[0].length > 0) {
      $(".js_search_btn").addClass("active");
    } else {
      $(".js_search_btn").removeClass("active");
    }
  }
  onInputChange(e: any) {
    if (e.length > 0) {
      $(".js_search_btn").addClass("active");
    } else {
      $(".js_search_btn").removeClass("active");
    }
  }

  onKeyDown(e: any) {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (e.target.value.trim().length > 2) {
        this.props.updateSearchTerm(e.target.value);
        let analytics = new Analytics();
        analytics.RecordEvent(
          "rts-search-click",
          this.searchref.current.getInstance().state.text
        );
      }
    }

    return;
  }

  clearSearch(e: any) {
    e.preventDefault();
    if (e.target.className.indexOf("active") > 0) {
      this.searchref.current.getInstance().clear();
      e.target.className = "js_search_btn search_btn";
    }
  }

  loadTypeAhead(fetced: boolean, opts: any) {
    // console.log(opts);
    if (fetced) {
      return (
        <Typeahead
          {...this.state}
          ref={this.searchref}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onInputChange={this.onInputChange}
          maxResults={10}
          bsSize="large"
          minLength={3}
          emptyLabel="no results "
          labelKey="name"
          options={opts}
          placeholder=""
        />
      );
    } else {
      return (
        <Typeahead
          {...this.state}
          bsSize="large"
          minLength={3}
          emptyLabel="no results "
          labelKey="name"
          options={["loading"]}
          placeholder="loading.."
        />
      );
    }
  }
  resetCacheData(e: any) {
    e.preventDefault();
    this.props.resetCache();
  }

  quickTipsOnClick(e: any) {
    e.preventDefault();
    this.props.updateRedirect(true, "/quicktips");
  }
  render() {
    // console.log("home render");
    // console.log(this.props);
    if (this.props.redirect) {
      // console.log(this.props.term.toString());
      const url = "/results/" + this.props.term;
      return <Redirect from="/" to={url} />;
    } else {
      return (
        <React.Fragment>
          {/* {this.renderRedirect()} */}
          <section className="home">
            <div className="container">
              <UpdateAvailable show={this.props.cacheupdated} />
              <div className="row">
                <div className="col">
                  <h1>Welcome!</h1>

                  <form
                    className="search-form"
                    action="results"
                    onSubmit={this.submit}
                  >
                    <label htmlFor="search-field">Search Part Number</label>

                    <div className="input-group search">
                      {this.loadTypeAhead(
                        this.props.fetched,
                        this.props.filters
                      )}
                      <span
                        className="js_search_btn search_btn"
                        onClick={this.clearSearch}
                      />
                    </div>
                    <div className="input-tip">
                      <strong>Tip:</strong> You can search by full or partial
                      part ID.
                      <br />
                      (Example: ABC1235 or ABC12)
                    </div>
                    <button type="submit" className="btn btn-solid btn-blue">
                      Submit
                    </button>
                    <div className="input-tip">
                      For help saving this app to your home screen, see our
                      <a
                        href="/quicktips"
                        onClick={this.quickTipsOnClick}
                        className="header-link home"
                      >
                        {" "}
                        Quick Tips{" "}
                      </a>
                      page
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state: any, customProps: any) => {
  return {
    xrefs: state.xrefs,
    fetching: state.fetching,
    fetched: state.fetched,
    filters: state.filters,
    resx: state.resx,
    parts: state.parts,
    baseurl: state.baseurl,
    error: state.error,
    term: state.term,
    redirect: state.redirect,
    lastUrl: state.lastUrl,
    cacheupdated: state.cacheupdated,
    cookies: customProps.cookies
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSearchTerm: (term: string) => dispatch(updateSearchTermRequest(term)),
    updateSelectTerm: (term: string) => dispatch(updateSelectRequest(term)),
    updateRedirect: (sr: boolean, ru: string) =>
      dispatch(updateRedirectRequest(sr, ru)),
    resetCache: () => dispatch(resetCacheRequest())
  };
};
// const mapDispatchToProps = (dispatch: any) => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Home));
