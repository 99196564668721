import * as React from "react";
import Slide1 from "./Slide1";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";
import DetailsFooter from "./DetailsFooter";
import {
  updateDetailsRequest,
  selectedTabRequest,
  updateWidthRequest
} from "src/reducers";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router";

interface IProps extends React.Props<Tabs> {
  updateDetails: (id: string, xprod: string, cb: string) => void;
  selectedTab: (tab: string) => void;
  tabid: string;
  updateWidth: (width: number) => void;
  single: boolean;
  timeout: number;
  details: any;
  baseurl: any;
  width: number;
}
class Tabs extends React.Component<IProps, {}> {
  private owlRef: any;
  private isOffline: boolean;
  constructor(props: any) {
    super(props);

    this.changeTab = this.changeTab.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.checkLoaded = this.checkLoaded.bind(this);
    this.owlRef = React.createRef();
    this.isOffline = true;
    if ("serviceWorker" in navigator && navigator.onLine) {
      this.isOffline = false;
    } else if (!("serviceWorker" in navigator)) {
      this.isOffline = false;
      // no service worker, so assume always online
    }
  }

  changeTab(e: any) {
    e.preventDefault();
    this.props.selectedTab(e.target.getAttribute("data-target"));
  }
  updateDimensions(e: any) {
    //  console.log(e);
    e.preventDefault();
    this.props.updateWidth($(window).width() as number);
  }
  componentWillMount() {
    //  console.log("will mount");
    window.addEventListener("resize", this.updateDimensions);
  }
  componentDidMount() {
    if (this.props.single) {
      $(".notification.vanish")
        .delay(this.props.timeout)
        .slideUp();
    }
    this.props.updateWidth($(window).width() as number);

    this.checkLoaded();
  }

  checkLoaded = () => {
    if (this.props.width < 992) {
      this.startCarousel();
    } else {
      this.stopCarousel();
    }
  };
  startCarousel = () => {
    // console.log("Start Carousel...");
    $(".js_owl").owlCarousel({
      loop: false,
      items: 1,
      responsiveClass: true,
      margin: 30,
      autoHeight: false
    });
  };

  stopCarousel = () => {
    // console.log("Stop Carousel...");
    var owl = $(".js_owl");
    owl.trigger("destroy.owl.carousel");
    owl.addClass("off");
  };
  componentDidUpdate() {
    this.checkLoaded();
  }

  render() {
    let tab1Class = "";
    let tab2Class = "";
    let tab3Class = "";
    let slide1Class = "slide-object";
    let slide2Class = "slide-object";
    let slide3Class = "slide-object";
    if (this.props.tabid === undefined || this.props.tabid === "tab1") {
      tab1Class = "active";
      slide1Class = "slide-object active";
    } else if (this.props.tabid === "tab2") {
      tab2Class = "active";
      slide2Class = "slide-object active";
    }
    if (this.props.tabid === "tab3") {
      tab3Class = "active";
      slide3Class = "slide-object active";
    }
    return (
      <div className="row">
        <div className="col">
          <div className="slideTabs">
            <div className="slideTabsNavigation">
              <ul>
                <li>
                  <a
                    onClick={this.changeTab}
                    href="#"
                    className={tab1Class}
                    data-control="slideTab"
                    data-target="tab1"
                  >
                    Overview
                  </a>
                </li>
                <li>
                  <a
                    onClick={this.changeTab}
                    href="#"
                    className={tab2Class}
                    data-control="slideTab"
                    data-target="tab2"
                  >
                    Specifications
                  </a>
                </li>
                <li>
                  <a
                    onClick={this.changeTab}
                    href="#"
                    className={tab3Class}
                    data-control="slideTab"
                    data-target="tab3"
                  >
                    Resources
                  </a>
                </li>
              </ul>
            </div>
            <div className="slideTabsContent">
              <div className="js_owl owl-carousel owl-theme off">
                <div id="tab1" className={slide1Class}>
                  <Slide1
                    part={this.props.details}
                    baseImageUrl={this.props.baseurl}
                  />
                </div>
                <div id="tab2" className={slide2Class}>
                  <Slide2 spec={this.props.details.sd} />
                </div>
                <div
                  id="tab3"
                  ref={this.owlRef}
                  onLoad={this.checkLoaded}
                  className={slide3Class}
                >
                  <Slide3
                    part={this.props.details}
                    baseImageUrl={this.props.baseurl}
                    isOffline={this.isOffline}
                  />
                </div>
              </div>
            </div>
            <article className="notification gray">
              <DetailsFooter />
            </article>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    baseurl: state.baseurl,
    width: state.width,
    details: state.details,
    timeout: state.successtimeout,
    single: state.single,
    tabid: state.tabid
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateDetails: (term: string, xprod: string, brand: string) =>
      dispatch(updateDetailsRequest(term, xprod, brand)),
    selectedTab: (tab: string) => dispatch(selectedTabRequest(tab)),
    updateWidth: (width: number) => dispatch(updateWidthRequest(width))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Tabs));
