import * as React from "react";
import Accessories from "./Accessories";
import ProductOverview from "../common/ProductOverview";
interface IProps {
  part: any;
  baseImageUrl: any;
}
const Slide1: React.StatelessComponent<IProps> = ({ part, baseImageUrl }) => {
  let application = <React.Fragment />;
  if (part.app !== undefined && part.app.length > 0) {
    application = (
      <React.Fragment>
        <h2>Application</h2>
        <p>{part.app}</p>
      </React.Fragment>
    );
  }
  return (
    <div>
      <div className="row">
        <div className="col">
          <h1 className="d-block d-md-none">OVERVIEW</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-6 offset-3 col-sm-4 offset-sm-0 order-xs-first order-sm-last">
          <img
            src={baseImageUrl + part.fn}
            onError={(e: any) => {
              e.target.style.display = "none";
            }}
            className="img-fluid pdp-image"
          />
        </div>

        <div className="col-sm-8 order-xs-last order-sm-first">
          {application}
          <ProductOverview overview={part.desc} />
          <Accessories acc={part.acc} />
        </div>
      </div>
    </div>
  );
};
export default Slide1;
