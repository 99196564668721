import config from "src/config";
export const FETCH_START = "FETCH_START";
export const FETCH_COMPLETED = "FETCH_COMPLETED";
export const FETCH_FAILURE = "FETCH_FAILURE";
export const ADD_PARTS = "ADD_PARTS";
export const UPDATE_PARTS = "UPDATE_PARTS";
export const UPDATE_SEARCH_TERM = "UPDATE_SEARCH_TERM";
export const UPDATE_DETAILS_ID = "UPDATE_DETAILS_ID";
export const UPDATE_TAB_ID = "UPDATE_TAB_ID";
export const UPDATE_WIDTH = "UPDATE_WIDTH";
export const UPDATE_IS_SINGLE = "UPDATE_IS_SINGLE";
export const REDIRECT_TO = "REDIRECT_TO";
export const REDIRECT_TO_DETAILS = "REDIRECT_TO_DETAILS";
export const UPDATE_SINGLE_DETAILS_ID = "UPDATE_SINGLE_DETAILS_ID";
export const fetchPartsBegin = () => ({ type: FETCH_START });
export const UPDATE_SERVICEWORKER = "UPDATE_SERVICEWORKER";
export const updateServiceWorker = () => ({ type: UPDATE_SERVICEWORKER });
export const UPDATE_CACHE = "UPDATE_CACHE";
export const RESET_CACHE = "RESET_CACHE";
export const UPDATE_SELECT_TERM = "UPDATE_SELECT_TERM";
export const API_URL = config.apiurl;

export const resetCacheRequest = () => ({
  type: RESET_CACHE
});

export const updateCacheRequest = () => ({
  type: UPDATE_CACHE
});

export const redirectDetailsRequest = (
  cu: string,
  cx: string,
  cb: string,
  sr: boolean,
  ru: string
) => ({
  type: REDIRECT_TO_DETAILS,
  payload: { cu, cx, cb, sr, ru }
});

export const updateSinglePartDetailsRequest = (
  cu: string,
  cx: string,
  cb: string,
  sing: boolean
) => ({
  type: UPDATE_SINGLE_DETAILS_ID,
  payload: { cu, cx, cb, sing }
});

export const updateRedirectRequest = (sr: boolean, ru: string) => ({
  type: REDIRECT_TO,
  payload: { sr, ru }
});

export const updateWidthRequest = (width: number) => ({
  type: UPDATE_WIDTH,
  payload: width
});

export const selectedTabRequest = (tab: string) => ({
  type: UPDATE_TAB_ID,
  payload: tab
});

export const updateSearchTermRequest = (term: string) => ({
  type: UPDATE_SEARCH_TERM,
  payload: term
});
export const updateSelectRequest = (term: string) => ({
  type: UPDATE_SELECT_TERM,
  payload: term
});

export const updateDetailsRequest = (cu: string, cx: string, cb: string) => ({
  type: UPDATE_DETAILS_ID,
  payload: { cu, cx, cb }
});

export const isSingleRequest = (single: boolean) => ({
  type: UPDATE_IS_SINGLE,
  payload: single
});

export const fetchPartsSuccess = (parts: any) => ({
  type: FETCH_COMPLETED,
  payload: { parts }
});

export const fetchPartsFailure = (error: any) => ({
  type: FETCH_FAILURE,
  payload: { error }
});

export function fetchParts() {
  return (dispatch: any) => {
    dispatch(fetchPartsBegin());
    return fetch(API_URL)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(fetchPartsSuccess(json));
        return json.products;
      })
      .catch(error => dispatch(fetchPartsFailure(error)));
  };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response: any) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
