import * as React from "react";
interface IProps {
  spec: any;
}
const Slide2: React.StatelessComponent<IProps> = ({ spec }) => {
  if (spec === undefined || spec === "") {
    return <React.Fragment>no data</React.Fragment>;
  }
  const specifications = JSON.parse(spec);
  const keys = Object.keys(specifications);

  return (
    <React.Fragment>
      <h1 className="d-block d-md-none">SPECIFICATIONS</h1>
      {keys.map(function(name, index) {
        if (specifications[name].length > 0) {
          return (
            <React.Fragment key={index}>
              <h2 className="spec">{name}</h2>
              {Object.keys(specifications[name]).map(function(name1, index1) {
                return (
                  <ul key={index1}>
                    <li>{specifications[name][name1]}</li>
                  </ul>
                );
              })}
            </React.Fragment>
          );
        } else {
          return <React.Fragment key={index} />;
        }
      })}
    </React.Fragment>
  );
};
export default Slide2;
